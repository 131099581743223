import { goodsSkuPage } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { tree , categoryConfig, goodsCategoryInfo} from '@/api/goods/goodscategory';
import { addCollect, deleteCollect, isCollect } from "@/api/goods/goods_collect"
export default {
    data: () => {
        return {
            cargoList: [],
            shopList: [], // ข้อมูลผลิตภัณฑ์ใน Store
            brandList: [], // รายชื่อแบรนด์
            attributeList: [], // รายการคุณสมบัติ
			categoryConfig:{},
			goodsCategoryTree: [],
            brandInitialList: [],
            currentInitial: "", // พาร์ติชันแบรนด์ถูกเลือกในขณะนี้
            choosedBrand: "", // แบรนด์ที่เลือก,
            hasChoosedAttrs: false, // เรียกคืนคุณสมบัติที่เลือก
            total: 0,
            keyword: "",
            catewords:'',
            currentPage: 1,
            pageSize: 20,
            is_free_shipping: 0,
            is_own: "",
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                coupon: 0
            },
            loading: true,
            whetherCollection: 0,
			first_index:0,
			two_index: 0,
			first_child_list: [],
			first_index_active: 0,
			testingNumberState: false,
        }
    },
    created() {
		this.getCategoryConfig()
        this.keyword = this.$route.query.keyword || ""
		if( this.$route.query.keyword ) window.document.title = `${this.$route.query.keyword} - ${this.siteInfo.site_name}`

        this.filters.category_id = this.$route.query.category_id || ""
        this.filters.category_level = this.$route.query.level || ""
        this.filters.brand_id = this.$route.query.brand_id || ""
        this.filters.coupon = this.$route.query.coupon || 0
        this.getGoodsList()
        if (this.$route.query.category_id) {
            this.categorySearch()
        }
		
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "siteInfo"])
    },
    methods: {
        /**
         * ค้นหาหมวดหมู่ผลิตภัณฑ์
         */
        categorySearch() {
            goodsCategoryInfo({
                category_id: this.filters.category_id
            }).then(res => {
				console.log(this.filters.category_id)
				console.log(this.filters.category_id)
				console.log(this.filters.category_id)
                if (res.code == 0 && res.data) {
					console.log(res.data.category_full_name)
                    this.catewords = res.data.category_full_name
					this.first_index = res.data.category_id_1
					this.two_index = res.data.category_id_2
					this.first_child_list = res.data.child_list
					this.first_index_active= 0
					window.document.title = `${res.data.category_name} - ${this.siteInfo.site_name}`
                }
                
            }).catch(err => {

            })
        },
		setFiindex( item ) {
			console.log(item,'setFiindex')
			this.first_index_active = item.category_id
			window.document.title = `${item.category_name} - ${this.siteInfo.site_name}`
		},
		gotocat( ) {
	
			//this.$router.push("/list?category_id=" + data)
			// this.router.go({path: '/login'})
			//this.$router.go({
			//	path: '/list',
			//	query: {
			//		category_id: data
			//	}
			//});
			this.currentPage = 1
			  this.getCategoryConfig()
			  this.keyword = this.$route.query.keyword || ""
			  if( this.$route.query.keyword ) window.document.title = `${this.$route.query.keyword} - ${this.siteInfo.site_name}`
			  
			  this.filters.category_id = this.$route.query.category_id || ""
			  this.filters.category_level = this.$route.query.level || ""
			  this.filters.brand_id = this.$route.query.brand_id || ""
			  this.filters.coupon = this.$route.query.coupon || 0
			  this.getGoodsList()
			  if (this.$route.query.category_id) {
			      this.categorySearch()
			  }
			
			
			
		},
	
		setSiindex() {
			this.first_index_active = 0
			
		},
		getCategoryConfig(){
			categoryConfig({
				
			})
			.then(res => {
				if (res.code == 0 && res.data) {
					this.categoryConfig = res.data;
					this.getTree(res.data);
				}
			})
			.catch(err => {
				this.$notify.error({title: 'Error',message: err.message});
			});
		},
		
		getTree(categoryConfig) {
			tree({
				level: 3,
				template: 2
			})
				.then(res => {
					if (res.code == 0 && res.data) {
						this.goodsCategoryTree = res.data || [];
						for(let i =0;i<this.goodsCategoryTree.length;i++) {
							if(this.goodsCategoryTree[i].child_list > 3) {
							
							}
						}
					}
				})
				.catch(err => {
					this.$notify.error({title: 'Error',message: err.message});
				});
		},
        addToCart(item) {
            this.$store
                .dispatch('cart/add_to_cart', item)
                .then(res => {
                    var data = res.data
                    if (data > 0) {
                        this.$notify({
                           title: 'เรียบร้อยแล้ว',
                            message: "หยิบใส่ตะกร้าสําเร็จ",
                            title: 'เรียบร้อยแล้ว',type: "success"
                        })
                    }
                })
                .catch(err => err);
        },
        async isCollect(item) {
            await isCollect({ goods_id: item.goods_id }).then(res => {
                this.whetherCollection = res.data
                if(this.whetherCollection == 0){
                    item.isCollection = false
                }else{
                    item.isCollection = true
                }
            })
                .catch(err => err);
        },
        async editCollection(item) {
           await this.isCollect(item)
            const { goods_id, sku_id, site_id, sku_name, sku_price, sku_image } = item;
            if (this.whetherCollection == 0) {
                await addCollect({ goods_id, sku_id, site_id, sku_name, sku_price, sku_image })
                    .then(res => {
                        this.$notify({
                            message: 'ความสําเร็จในการรวบรวม',
                            title: 'เรียบร้อยแล้ว',type: 'success'
                        });
                        item.isCollection = true
                    })
                    .catch(err => err);
            } else {
                await deleteCollect({
                    goods_id
                }).then(res => {
                    if (res.data > 0) {
                        this.$notify({
                            message: 'ไม่ประสบความสําเร็จ',
                            title: 'เรียบร้อยแล้ว',type: 'success'
                        });
                        item.isCollection = false
                    }
                })
                    .catch(err => err);
            }
        },
        getGoodsList() {
            const currentArr = []
            if (this.attributeList) {
                this.attributeList.forEach(item => {
                    if (item.child) {
                        item.child.forEach(subitem => {
                            if (subitem.selected) {
                                currentArr.push({
                                    attr_id: item.attr_id,
                                    attr_value_id: subitem.attr_value_id
                                })
                            }
                        })
                    }
                })
            }

            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.siteId,
                keyword: this.keyword,
                attr: currentArr.length > 0 ? JSON.stringify(currentArr) : "",
                ...this.filters
            }
            goodsSkuPage(params || {})
                .then(res => {
					console.log(res,'goodsSkuPage')
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.cargoList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },


        onChooseBrand(brand) {
            this.choosedBrand = brand
            this.filters.brand_id = brand.id
            this.getGoodsList()
        },

        setMuiltChoose(attr) {
            this.$set(attr, "isMuiltSelect", !attr.isMuiltSelect)
            this.getGoodsList()
        },

        setAttrSelected(item, subitem) {
            if (item.child) {
                item.child.forEach(attr => {
                    this.$set(attr, "selected", false)
                })
            }

            this.$set(subitem, "selected", true)
            this.$set(item, "selectedValue", subitem.attr_value_name)
            this.getGoodsList()
        },

        setAttrSelectedMuilt(item, subitem) {
            this.$set(subitem, "selected", !subitem.selected)
            var selectedValueArray = []

            if (subitem.selected) {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) == -1) {
                    selectedValueArray.push(subitem.attr_value_name)
                }
            } else {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) !== -1) {
                    selectedValueArray.splice(selectedValueArray.indexOf(subitem.attr_value_name), 1)
                }
            }

            this.$set(item, "selectedValue", selectedValueArray.toString())
            this.getGoodsList()
        },

        colseBrand() {
            this.choosedBrand = ""
            this.filters.brand_id = ""
            this.getGoodsList()
        },

        colseAttr(item) {
            item.selectedValue = ""
            item.child.forEach(subitem => {
                subitem.selected = false
            })

            item.isMuiltSelect = false

            this.getGoodsList()
        },

        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsList()
        },
        handlePriceRange() {
			if( Number(this.filters.min_price )> Number(this.filters.max_price )){
				// es6แยกส่วนการมอบหมาย
				[ this.filters.min_price , this.filters.max_price ] = [ this.filters.max_price , this.filters.min_price ]
			} 
            this.getGoodsList()
        },
        handleChangeInitial(initial) {
            this.currentInitial = initial
        },
        changeSort(type) {
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }

            this.getGoodsList()
        }
    },
    watch: {
        is_free_shipping: function (val) {
            this.filters.is_free_shipping = val ? 1 : ""
            this.getGoodsList()
        },
        is_own: function (val) {
            this.filters.is_own = val ? 1 : ""
            this.getGoodsList()
        },
        $route: function (curr) {
			console.log(curr,'')
			if( curr.query.keyword ) window.document.title = `${curr.query.keyword} - ${this.siteInfo.site_name}`
			if(curr.query.level){
				this.filters.category_level = curr.query.level
				this.filters.category_id = curr.query.category_id
				this.getGoodsList()
				if(curr.query.level != 3){
					this.categorySearch()
				}
			}
            if (curr.query.category_id == undefined) {
                this.catewords = "$route$route"
                this.currentPage = 1
                this.keyword = curr.query.keyword
                this.filters.category_id = curr.query.category_id || ""
                this.filters.category_level = curr.query.level || ""
                this.filters.brand_id = curr.query.brand_id || ""
                this.getGoodsList()
            }
        }
    }
}
