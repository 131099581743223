<template>
	<div class="goods-list" v-loading="loading">
		<!-- ค้นหาคำสำคัญ -->
		<div class="search_bread"  v-if="keyword">
			<router-link to="/">หน้าแรก</router-link>
			<span class="padd-10">/</span>
			<span class="keyword">{{ keyword}}</span>
		</div>
		<div class="search_bread" style="        border-bottom: 1px solid rgb(239 239 244 / 0%); " v-else-if="!keyword && catewords">
			<router-link to="/">หน้าแรก</router-link>
			<span class="padd-10">></span>
			<router-link :to="{ path: '/list', query: { category_id: first_index, level: 1 } }">{{ catewords.split('/')[0] }}</router-link>
			<!-- <span class="keyword">{{ catewords.split('/')[0] }}</span> -->
			<span v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]" class="padd-10">></span>
			<span v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]" class="keyword">{{ catewords.split('/')[1] }}</span>
		</div>
		<div class="search_bread" style="        border-bottom: 1px solid rgb(239 239 244 / 0%); " v-else>
			<router-link to="/">หน้าแรก</router-link>
			<span class="padd-10">></span>
			<router-link to="/">ทั้งหมด</router-link>
			<!-- <span class="keyword">{{ catewords.split('/')[0] }}</span> -->
			
		</div>
		
		<div class="col-12" style="padding-top: 13px;">
		                    <div class="section-title mb-30">
		                        <h2 style="font-family: 'sukhumvittadmai', sans-serif !important;" v-if="!keyword && catewords">{{ catewords.split('/')[0] }}</h2>
		                       <h2 style="font-family: 'sukhumvittadmai', sans-serif !important;" v-else>ทั้งหมด</h2>
		                    </div>
		                </div>
		<div v-if="!keyword && first_child_list" class="search_bread" style="    margin-top: -10px
px
;
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 18px;">
			<p v-if="(filters.category_level == 2 || filters.category_level == 3) && catewords.split('/')[1]">{{ catewords.split('/')[1] }}：</p>
			<p v-if="catewords.split('/')[0]  && !catewords.split('/')[1]" style="    font-size: 14px;
    font-weight: 600; font-family: 'sukhumvittadmai', sans-serif !important;">หมวดหมู่ทั้งหมด ：</p>
			<ul>
				<li :class="{active: first_index_active == 0}" @click="setSiindex()"><router-link :to="{ path: '/list', query: { category_id: two_index, level: 2 } }">ทั้งหมด</router-link></li>
				
			
									 
									 
				<li :class="{active: item.category_id == first_index}" @click="gotocat( )" v-for="(item, index) in goodsCategoryTree" :key="index" @mouseover="selectedCategory = item.category_id" @mouseleave="selectedCategory = -1" ><router-link :to="{ path: '/list', query: { category_id: item.category_id } }">{{item.category_name }}</router-link></li>
				
				
				
			</ul>
		</div>

		<!-- พื้นที่บันทึกตัวกรองแบรนด์ -->
		<!-- <div class="attr_filter" v-if="catewords">
			<el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
				<span v-if="choosedBrand" class="title">ยี่ห้อ：</span>
				{{ choosedBrand.brand_name }}
			</el-tag>
			<span v-for="item in attributeList" :key="item.attr_id">
				<el-tag type="info" v-if="item.selectedValue" closable @close="colseAttr(item)" effect="plain">
					<span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
					{{ item.selectedValue }}
				</el-tag>
			</span>
		</div> -->

		<!-- พื้นที่ตัวกรองแอตทริบิวต์แบรนด์ -->
		<template>
			
		
			<div class="category" v-if="brandList.length || attributeList.length">
				<!-- ยี่ห้อ -->
				<!-- <div class="brand">
					<div class="table_head">ยี่ห้อ：</div>
					<div class="table_body">
						<div class="initial">
							<span type="info" effect="plain" hit @mouseover="handleChangeInitial('')">ทุกยี่ห้อ</span>
							<span type="info" effect="plain" hit v-for="item in brandInitialList" :key="item" @mouseover="handleChangeInitial(item)">{{ (item || '').toUpperCase() }}</span>
						</div>
						<div class="content">
							<el-card v-for="item in brandList" :key="item.id" body-style="padding: 0;height: 100%;" shadow="hover" v-show="currentInitial === '' || item.brand_initial === currentInitial"
							 class="brand-item">
								<el-image :src="$img(item.image_url || defaultGoodsImage)" :alt="item.brand_name" :title="item.brand_name" fit="contain"
								 @click="onChooseBrand(item)" />
							</el-card>
						</div>
					</div>
				</div> -->

				<!-- คุณลักษณะ -->
				<div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
					<div class="table_head">{{ item.attr_name }}：</div>
					<div class="table_body">
						<div class="content">
							<span v-for="subitem in item.child" :key="subitem.attr_value_id">
								<el-checkbox :label="subitem.attr_value_name" v-if="item.isMuiltSelect" :checked="subitem.selected" @change="setAttrSelectedMuilt(item, subitem)"></el-checkbox>
								<el-link :underline="false" v-else @click="setAttrSelected(item, subitem)">{{ subitem.attr_value_name }}</el-link>
							</span>
						</div>
					</div>
					<div class="table_op">
						<el-button size="small" icon="el-icon-circle-plus-outline" @click="setMuiltChoose(item)">ปรนัย</el-button>
					</div>
				</div>
			</div>
		</template>

		<div class="list-wrap">
			
			<div class="list-right">
				<!-- จัดเรียงพื้นที่ตัวกรอง -->
				<div>
					<div class="sort">
						<div class="item" @click="changeSort('')">
							<div class="item-name">ครอบคลุม</div>
						</div>
						<div class="item" @click="changeSort('sale_num')">
							<div class="item-name">ฝ่ายขาย</div>
							<i v-if="filters.order === 'sale_num' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
							<i v-else class="el-icon-arrow-up el-icon--up" />
						</div>
						<div class="item" @click="changeSort('discount_price')">
							<div class="item-name">ราคา</div>
							<i v-if="filters.order === 'discount_price' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
							<i v-else class="el-icon-arrow-up el-icon--up" />
						</div>
						<div class="item-other">
							<el-checkbox label="จัดส่งฟรี" v-model="is_free_shipping"></el-checkbox>
						</div>
						<!-- <div class="item-other">
							<el-checkbox label="อาชีพอิสระ" v-model="is_own"></el-checkbox>
						</div> -->
						<div class="input-wrap">
							<div class="price_range">
								<el-input placeholder="ราคาต่ำสุด" size="small" v-model="filters.min_price"></el-input>
								<span>—</span>
								<el-input placeholder="ราคาสูงสุด" size="small" v-model="filters.max_price"></el-input>
							</div>
							<el-button plain size="mini" @click="handlePriceRange" style="    padding: 3px 15px;
    border-radius: 0px;">ค้นหา</el-button>
						</div>
					</div>
				</div>
				<div class="cargo-list" v-if="cargoList.length">
					
					
					
					<div class="tab-content confetti-wrapper" id="hotdeal-tab-content">
								<div id="waitingLoadCategory" style="text-align: center;display: none;margin-top:20px;">
									<img class="lazy" data-original="/img/AjaxLoader.gif" src="https://www.priceza.com/img/AjaxLoader.gif" style="display: inline;">
								</div>
								<div role="tabpanel" class="tab-pane fade in active" id="hotdeal-tab-1">
										<div id="hotdeal-tab-container-1" class="pz-pdb_container pz-pdb_gridview-4 pz-pdb_home-hotdeal">
											<div class="pz-pdb-item pd-load-more-1" v-for="(item, index) in cargoList" :key="item.goods_id" >
							 			<div class="pz-pdb-card pz-pdb-item-action" data-productid="533424296">
								 			<span class="pz-pdb-action__save pz-user-action" data-action="want"><i class="iconic-save-blank"></i></span>
											<div class="pz-pdb-item-info">
												<div class="pz-pdb_media">
													<a  target="_blank" rel="nofollow" >
														<img @click="$router.push({ path: '/sku-' + item.sku_id })" class="pz-pdb_media--img lazy-pd"  width="200" height="200"  :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" style="display: inline;">
															<div class="pz-label__circle pz-bg--red pz-label--discount" v-if="item.is_free_shipping == 1">
																จัดส่ง<b class="pz-label--bold" style="    font-family: 'sukhumvittadmai', sans-serif;">ฟรี</b>
															</div>
														</a>
												</div>
												<div class="pz-pdb_detail">
													<a  rel="nofollow" >
															<h3 class="pz-pdb_name" title="" @click="$router.push({ path: '/sku-' + item.sku_id })" >{{ item.goods_name }}{{ item.goods_name }}</h3>
																</a>
														<small style="    color: #097!important; font-weight: 700; font-family: 'sukhumvittadmai', sans-serif;" >ประหยัด : </small>
														<span class="pz-pdb-price" style="    color: #097!important; font-weight: 700;">
															<span content="THB" style="    font-family: 'sukhumvittadmai', sans-serif;">฿</span><span content="2499.0" style="      font-family: 'sukhumvittadmai', sans-serif;">{{formatPrice(item.market_price-showPrice(item)) }}</span>
															<del class="pz-base-price"></del></span>
														</div>
												</div>
											<div class="pz-pdb-item-footer pz-pd-merchant-tablet">
												<span class="pz-pdb-price">
													<span content="THB" style=" font-weight: 700;">฿</span><span content="2499.0" style=" font-weight: 700;   font-family: 'sukhumvittadmai', sans-serif;" >{{ formatPrice(showPrice(item)) }}</span>
													<del class="pz-base-price" style="    margin-left: 7px;   font-family: 'sukhumvittadmai', sans-serif;">฿{{ formatPrice2(item.market_price) }}</del></span>
													<a class="btn pz-btn-product" style="color: #ffff;" @click="addToCart(item)" rel="nofollow" >ใส่รถเข็น
													</a>
												</div>
										</div>
									</div>
							
							
							
							
							
							
							
							
							
							
							
							</div><!--row-->
										
									</div>
								
								
								
								
								
								
								
								
								
								
								
								
								
								</div>
					
					<div class="goods-info" style="display: none;">



						<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" @click="$router.push({ path: '/sku-' + item.sku_id })">
							<img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
							<div class="price-wrap">
								<div class="price">
									<p>฿</p>
									{{ showPrice(item) }}
									<div class="price-icon-wrap">
										<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('upload/uniapp/index/VIP.png')"/>
										<img v-else-if="item.promotion_type==1" :src="$img('upload/uniapp/index/discount.png')"/>
									</div>
								</div>
								<div class="market-price">฿{{ item.market_price }}</div>
							</div>
							<div class="goods-name">{{ item.goods_name }}</div>
							<div class="sale-num">
								<p>{{ item.sale_num || 0 }}</p>คนจ่าย
							</div>
							<!-- <div class="shop_name">{{item.site_name}}</div> -->
							<div class="saling">
								<div class="free-shipping" v-if="item.is_free_shipping == 1">จัดส่งฟรี</div>
								<!-- <div class="is_own" v-if="item.is_own == 1">อาชีพอิสระ</div> -->
								<div class="promotion-type" v-if="item.promotion_type == 1">จำกัดส่วนลด</div>
							</div>
						</div>




					</div>
					<div class="pager" style="padding-top: 41px;">
						<el-pagination background :pager-count="5" :total="total" prev-text="หน้าที่แล้ว" next-text="หน้าต่อไป" :current-page.sync="currentPage"
						 :page-size.sync="pageSize" layout="total,prev, pager, next" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
						 hide-on-single-page></el-pagination>
					</div>
				</div>
			
				
				
				<div  class="el-empty" style="--el-empty-padding: 40px 0;
						    --el-empty-image-width: 160px;
						    --el-empty-description-margin-top: 20px;
						    --el-empty-bottom-margin-top: 20px;
						    --el-empty-fill-color-0:
						    var(--el-color-white);
						    --el-empty-fill-color-1: #fcfcfd;
						    --el-empty-fill-color-2: #f8f9fb;
						    --el-empty-fill-color-3: #f7f8fc;
						    --el-empty-fill-color-4: #eeeff3;
						    --el-empty-fill-color-5: #edeef2;
						    --el-empty-fill-color-6: #e9ebef;
						    --el-empty-fill-color-7: #e5e7e9;
						    --el-empty-fill-color-8: #e0e3e9;
						    --el-empty-fill-color-9: #d5d7de;
						    display: flex;
						    justify-content: center;
						    align-items: center;
						    flex-direction: column;
						    text-align: center;
						    box-sizing: border-box;
						    padding: var(--el-empty-padding);
						" v-else ><div data-v-eea730d8="" class="el-empty__image" style="width: 200px;"><img data-v-eea730d8="" src="https://i.imgur.com/VrthEHO.png"></div><div data-v-eea730d8="" class="el-empty__description"><p data-v-eea730d8="" style="font-family: sukhumvittadmai; font-family: 'sukhumvittadmai';
				font-weight: 500;
				margin-top: 27px;
				color: #b3b3b3; ">ไม่พบสินค้าที่คุณหาอยู่</p></div></div>
			</div>
		</div>
		
		
	</div>
</template>

<script>
	import BreadCrumbs from "@/components/BreadCrumbs";
	import GoodsRecommend from "@/components/GoodsRecommend";
	import list from "./list";

	export default {
		name: "list",
		components: {
			BreadCrumbs,
			GoodsRecommend
		},
		data: () => {
			return {};
		},
		mixins: [list],
		created() {},
		methods: {
			formatPrice(value) {
					if(0>value){
						return 0;
					}else{
			        let val = (value/1).toFixed(2).replace(',', '.')
			        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
					}
			    },
				formatPrice2(value) {
				       let val = (value/1).toFixed(0).replace(',', '.')
				        return val
				    },
			showPrice(data){
				let price = data.discount_price;
				if(data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
				return price;
			}
		}
	};
</script>

<style lang="scss" scoped>
	.padd-10{
		padding: 0 10px;
	}.goods-info .item {
     border-radius: 0.4rem;
   box-shadow: rgb(0 0 0 / 10%) 0px 0.0625rem 0.125rem 0px;
    
}
	.goods-list {
		background: #fff;
		padding-top: 10px;
	}

	.search_bread {
		display: inline-block;
		font-size: 14px;
		margin: 0px auto;
		width: 100%;
		padding: 10px;
		p{
			float: left;
		}
		li{
			float: left;
			padding: 0 10px;
		}
		.active a{
			color:#ff547b !important;
		}
	}

	.selected_border {
		border: 1px solid $base-color;
	}

	.attr_filter {
		.el-tag {
			margin-right: 5px;
			margin-bottom: 10px;
			border-radius: 0;

			.title {
				color: $base-color;
				font-size: 15px;
			}
		}
	}
.search_bread .active a{
	    color: #ae132a !important;
	}
	.category {
		margin: 0 auto 10px auto;
		border: 1px solid #eeeeee;

		.brand {
			border-bottom: 1px solid #eeeeee;
			display: flex;
			flex-direction: row;

			&:last-child {
				border-bottom: none;
			}

			.table_head {
				width: 140px;
				min-width: 140px;
				border-right: 1px solid #eeeeee;
				padding-left: 10px;
				padding-top: 10px;
				background-color: #f2f6fc;
			}

			.table_body {
				flex-grow: 1;

				.initial {
					margin: 5px auto 10px 10px;

					span {
						border: 0;
						margin: 0;
						padding: 5px 10px;
						border: 0;
						border-radius: 0;

						&:hover {
							background-color: $base-color;
							color: #ffffff;
						}
					}
				}

				.content {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding: 10px;
					width: 900px;

					.brand-item {
						margin-right: 10px;
						margin-top: 10px;
					}

					.el-card {
						width: 125px;
						height: 45px;

						&:hover {
							border: 1px solid $base-color;
							cursor: pointer;
						}
					}

					span {
						margin: auto 25px;
					}
				}
			}

			.table_op {
				margin-top: 5px;
				margin-right: 5px;
			}

			.el-image {
				width: 100%;
				height: 100%;
			}
		}
	}

	.list-wrap {
		overflow: hidden;
	}

	.goods-recommended {
		width: 200px;
		background-color: #fff;
		float: left;
		margin-right: 10px;
	}

	.sort {
		display: flex;
		align-items: center;

		.item {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;

			&:hover {
				background: $base-color;
				color: #fff;
			}
		}

		.item-other {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;
		}

		.input-wrap {
			display: flex;
			align-items: center;

			.price_range {
				margin-left: 60px;
			}

			span {
				padding-left: 10px;
			}

			.el-input {
				width: 150px;
				margin-left: 10px;
			}

			.el-button {
				margin: 0 17px;
			}
		}

		>div:first-child {
			border-left: 1px solid #f1f1f1;
		}
	}

	.goods-info {
		margin-top: 5px;
		display: flex;
		flex-wrap: wrap;

		.item {
			width: 220px;
			margin: 10px 20px 0 0;
			border: 1px solid #eeeeee00;
			padding-top: 10px;
			position: relative;
			padding-bottom: 5px;

			&:nth-child(4n) {
				margin-right: initial !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 198px;
				height: 198px;
				cursor: pointer;
				padding: 10px;
			}

			.goods-name {
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				height: 50px;
				cursor: pointer;

				&:hover {
					color: $base-color;
				}
			}

			.price-wrap {
				padding: 0 10px;
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				padding: 0 10px;
				display: flex;
				color: #838383;
				font-size: $ns-font-size-sm;

				p {
					color: #4759a8;
				}
			}

			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}

			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}

			.item-bottom {
				display: flex;
				margin-top: 5px;

				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}

				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}
	/*! CSS Used from: https://static1.priceza.com/css/bootstrap.min.css */
	a{background-color:transparent;}
	a:active,a:hover{outline:0;}
	b{font-weight:700;}
	small{font-size:80%;}
	img{border:0;}
	button{margin:0;font:inherit;color:inherit;}
	button{overflow:visible;}
	button{text-transform:none;}
	button{-webkit-appearance:button;cursor:pointer;}
	button::-moz-focus-inner{padding:0;border:0;}
	@media print{
	*,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;-webkit-box-shadow:none!important;box-shadow:none!important;}
	a,a:visited{text-decoration:underline;}
	a[href]:after{content:" (" attr(href) ")";}
	img{page-break-inside:avoid;}
	img{max-width:100%!important;}
	h3{orphans:3;widows:3;}
	h3{page-break-after:avoid;}
	}
	*{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
	:after,:before{-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box;}
	button{font-family:inherit;font-size:inherit;line-height:inherit;}
	a{color:#337ab7;text-decoration:none;}
	a:focus,a:hover{color:#23527c;text-decoration:underline;}
	a:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
	img{vertical-align:middle;}
	h3{font-family:inherit;font-weight:500;line-height:1.1;color:inherit;}
	h3{margin-top:20px;margin-bottom:10px;}
	h3{font-size:24px;}
	small{font-size:85%;}
	.btn{display:inline-block;padding:6px 12px;margin-bottom:0;font-size:14px;font-weight:400;line-height:1.42857143;text-align:center;white-space:nowrap;vertical-align:middle;-ms-touch-action:manipulation;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:none;border:1px solid transparent;border-radius:4px;}
	.btn:active:focus,.btn:focus{outline:5px auto -webkit-focus-ring-color;outline-offset:-2px;}
	.btn:focus,.btn:hover{color:#333;text-decoration:none;}
	.btn:active{background-image:none;outline:0;-webkit-box-shadow:inset 0 3px 5px rgba(0,0,0,.125);box-shadow:inset 0 3px 5px rgba(0,0,0,.125);}
	.fade{opacity:0;-webkit-transition:opacity .15s linear;-o-transition:opacity .15s linear;transition:opacity .15s linear;}
	.fade.in{opacity:1;}
	.tab-content>.tab-pane{display:none;}
	.tab-content>.active{display:block;}
	@media (max-width:767px){
	.hidden-xs{display:none!important;}
	}
	/*! CSS Used from: https://www.priceza.com/css/pz-home.min.css?v=4.0.53 */
	a{background-color:transparent;}
	a:active,a:hover{outline:0;}
	b{font-weight:700;}
	small{font-size:80%;}
	img{border:0;}
	button{color:inherit;font:inherit;margin:0;}
	button{overflow:visible;}
	button{text-transform:none;}
	button{-webkit-appearance:button;cursor:pointer;}
	button::-moz-focus-inner{border:0;padding:0;}
	[class^=icon-]:before{font-family:fontello;font-style:normal;font-weight:400;speak:none;display:inline-block;text-decoration:inherit;width:auto;margin-right:.2em;text-align:center;font-variant:normal;text-transform:none;margin-left:.2em;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
	.icon-repeat:before{content:'\e870';}
	button:focus,div:focus{outline:0;}
	a{text-decoration:none!important;cursor:pointer;-webkit-transition:all .2s cubic-bezier(.11,.85,1,1);}
	img{max-width:100%;width:auto;height:auto;}
	small{font-size:.75em;}
	b{font-weight:700;}
	h3{font-weight:400;line-height:1.2;color:#222;}
	h3{font-size:1.4em;}
	a{color:#595966;}
	a:hover{text-decoration:none;color:#f19b27;}
	.btn{font-weight:500;font-size:14px;}
	@media (min-width:768px){
	.tab-pane{position:relative;}
	}
	.iconic-save-blank{display:inline-block;background:url(https://www.priceza.com/img/icon.png) no-repeat;overflow:hidden;text-indent:-9999px;text-align:left;cursor:pointer;}
	.iconic-save-blank{background-position:-22px -41px;width:19px;height:18px;margin:0 7px 0 0;}
	@media (min-width:1025px){
	.pz-pdb-item-action .iconic-save-blank:hover{background-position:-2px -41px;width:19px;height:18px;}
	}
	.hotdeal-tab__load-more{margin-top:24px;text-align:center;}
	.hotdeal-tab__load-more__btn{width:180px;height:40px;border-radius:4px;background-color:#fff;border:solid 1px #4d575f;font-weight:500;color:#4d575f;}
	.hotdeal-tab__load-more__btn:hover{background-color:#fff;border:solid 1px #1e7be5;color:#1e7be5;}
	.pz-pdb-item{width:100%;display:inline-block;    border: 1px solid #eaeaea00; border-radius:8px;margin:1% 0;background-color:#fff;}
	.pz-pdb-card{position:relative;}
	.pz-pdb-item:hover{box-shadow:0 6px 25px rgba(0,0,0,.1),0 5px 15px rgba(0,0,0,.1);}
	.pz-pdb-item-info{padding:8px;width:100%;display:inline-block;position:relative;}
	.pz-pdb_media{position:relative;text-align:center;padding-right:5px;}
	.pz-pdb_media--img{width:100%;max-width: 180px;
    min-width: 180px; border-radius:4px;}
	.pz-label__circle{width:45px;height:45px;line-height:.95;font-size:12px;text-align:center;border-radius:50%;color:#fff;background-color:#ffa400;display:flex;flex-flow:column;align-items:center;justify-content:center;}
	.pz-bg--red{background-color:#f4364c;}
	.pz-pdb-item .pz-label--discount{position:absolute;top:0;left:0;}
	.pz-label--bold{display:block;font-size:110%;}
	.pz-pdb_name{font-size:16px;margin:15px 0 10px 0;display:-webkit-box;height:42px;-webkit-line-clamp:2;-webkit-box-orient:vertical;line-height:1.4;overflow:hidden;text-overflow:ellipsis;white-space:initial;color:#333f48;}
	.pz-pdb-price{font-size:18px;font-weight:500;color:#283138;}
	.pz-base-price{font-size:80%;font-weight:200;color:#999fa3;}
	.pz-pdb-item-footer{padding:8px;border-top:1px solid #eaeaea;display:flex;justify-content:space-between;align-items:center;font-size:.8em;min-height:40px;}
	.pz-pdb-action__save{position:absolute;right:5px;top:15px;z-index:100;}
	.pz-pdb_store--img{width:60px;vertical-align:unset;}
	.pz-btn-product{background-color:#1e7be5;border:0;color:#fff;transition:all .3s ease-out;-moz-transition:all .3s ease-out;-webkit-transition:all .3s ease-out;}
	.pz-btn-product:hover{color:#fff;background:#1e88e5;}
	.pz-pdb-item-footer .pz-btn-product{display:block;}
	.pz-rating-score-text{display:inline-block;position:relative;font-size:12px;white-space:nowrap;max-width:95px;overflow:hidden;text-overflow:ellipsis;text-align:right;}
	.pz-rating-score-text::before{content:'\e801';font-family:fontello;font-style:normal;font-weight:400;speak:none;display:inline-block;text-decoration:inherit;text-align:center;font-variant:normal;text-transform:none;position:relative;top:-2px;margin:0 -2px 0 1px;padding:0;color:#f19b27;}
	.pz-rating-score-text--bold{font-size:16px;font-weight:450;}
	@media (max-device-width:1024px){
	.pz-pd-merchant-tablet{flex-flow:row wrap;}
	.pz-pdb_home-hotdeal .pz-btn-product{padding:3px 6px;width:100%;font-size:12px;}
	}
	@media only screen and (min-device-width:768px) and (max-device-width:1024px) and (orientation:portrait){
	.pz-pd-merchant-tablet .pz-btn-product{width:100%;}
	}
	@media (max-width:767px){
	.pz-pdb_container{margin:0;}
	.pz-pdb_media{width:40%;float:left;}
	.pz-pdb_detail{width:60%;float:left;}
	.pz-pdb_home-hotdeal{box-sizing:border-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap;}
	.pz-pdb_home-hotdeal .pz-pd-merchant-tablet .pz-btn-product{margin-top:5px;}
	.pz-pdb_home-hotdeal .pz-pdb_store--img{width:49px;}
	.pz-pdb_home-hotdeal .pz-rating-score-text{width:72px;}
	.pz-pdb_home-hotdeal .pz-rating-score-text{top:0;font-size:10px;padding-left:0;}
	.pz-pdb_home-hotdeal .pz-rating-score-text--bold{font-size:12px;}
	.pz-pdb_home-hotdeal .pz-pdb-item{width:48.8%;float:left;margin:8px 8px 0 0;}
	.pz-pdb_home-hotdeal .pz-pdb-item:nth-child(even){margin-right:0;}
	.pz-pdb_home-hotdeal .pz-pdb_name{font-size:14px;margin-top:5px;display:-webkit-box;height:18px;-webkit-line-clamp:1;line-height:1.3;overflow:hidden;text-overflow:ellipsis;}
	.pz-pdb_home-hotdeal .pz-pdb_media{width:100%;float:none;}
	.pz-pdb_home-hotdeal .pz-pdb_detail{width:100%;float:none;}
	.pz-pdb_home-hotdeal .pz-pdb-price{font-size:14px;}
	.pz-pdb_home-hotdeal .pz-base-price{font-size:.7em;}
	.pz-pdb_home-hotdeal .pz-label__circle{width:35px;height:35px;font-size:10px;}
	.pz-pdb_home-hotdeal .pz-pdb_detail{position:relative;}
	}
	@media (max-width:360px){
	.pz-pdb_home-hotdeal .pz-pdb-item{width:48.6%;}
	}
	@media (min-width:768px){
	.pz-pdb_container.pz-pdb_gridview-4{margin-right:-1%;}
	.pz-pdb_gridview-4{box-sizing:border-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap;}
	.pz-pdb_gridview-4 .pz-pdb-item{width:32.33333333%;margin-right:1%;float:left;}
	.pz-pdb_media{min-height:200px;}
	.pz-pdb_detail{position:relative;font-size:16px;color:#333f48;}
	}
	@media (min-width:992px){
	.pz-pdb_gridview-4 .pz-pdb-item{width:24%;margin-right:1%;}
	}
	@media (min-width:1024px){
	.pz-pdb_home-hotdeal .pz-pdb-item{    width: 16.95%;
    margin-right: 22px;
    margin-left: 22px;}
	.pz-pdb_home-hotdeal .pz-pdb-item:nth-child(4n){margin-right:0;}
	}
	/*! CSS Used fontfaces */
	@font-face{font-family:fontello;font-display:swap;src:url(https://www.priceza.com/font/fontello.eot?32768);src:url(https://www.priceza.com/font/fontello.eot?32768#iefix) format("embedded-opentype"),url(https://www.priceza.com/font/fontello.woff2?32768) format("woff2"),url(https://www.priceza.com/font/fontello.woff?32768) format("woff"),url(https://www.priceza.com/font/fontello.ttf?32768) format("truetype"),url(https://www.priceza.com/font/fontello.svg?32768#fontello) format("svg");font-weight:400;font-style:normal;}.pz-btn-product {
    background-color: #414141;
    border: 0;
    border-radius: 37px;
    color: #fff;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
h2{margin-top:0;margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h2{font-size:calc(1.325rem + .9vw);}
@media (min-width:1200px){
h2{font-size:2rem;}
}
ul{padding-left:2rem;}
ul{margin-top:0;margin-bottom:1rem;}
label{display:inline-block;}
select{margin:0;font-family:inherit;font-size:inherit;line-height:inherit;}
select{text-transform:none;}
select{word-wrap:normal;}
select:disabled{opacity:1;}
::-moz-focus-inner{padding:0;border-style:none;}
.row>*{flex-shrink:0;width:100%;max-width:100%;padding-right:calc(var(--bs-gutter-x) * .5);padding-left:calc(var(--bs-gutter-x) * .5);margin-top:var(--bs-gutter-y);}
.col-12{flex:0 0 auto;width:100%;}
.d-flex{display:flex!important;}
@media (min-width:992px){
.d-lg-none{display:none!important;}
}
/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/nice-select.css */
.nice-select{-webkit-tap-highlight-color:transparent;background-color:#fff;border-radius:5px;border:solid 1px #e8e8e8;box-sizing:border-box;clear:both;cursor:pointer;display:block;float:left;font-family:inherit;font-size:14px;font-weight:normal;height:42px;line-height:40px;outline:none;padding-left:18px;padding-right:30px;position:relative;text-align:left!important;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;white-space:nowrap;width:auto;}
.nice-select:hover{border-color:#dbdbdb;}
.nice-select:active,.nice-select:focus{border-color:#999;}
.nice-select:after{border-bottom:2px solid #999;border-right:2px solid #999;content:'';display:block;height:5px;margin-top:-4px;pointer-events:none;position:absolute;right:12px;top:50%;-webkit-transform-origin:66% 66%;-ms-transform-origin:66% 66%;transform-origin:66% 66%;-webkit-transform:rotate(45deg);-ms-transform:rotate(45deg);transform:rotate(45deg);-webkit-transition:all 0.15s ease-in-out;transition:all 0.15s ease-in-out;width:5px;}
.nice-select .list{background-color:#fff;border-radius:5px;box-shadow:0 0 0 1px rgba(68, 68, 68, 0.11);box-sizing:border-box;margin-top:4px;opacity:0;overflow:hidden;padding:0;pointer-events:none;position:absolute;top:100%;left:0;-webkit-transform-origin:50% 0;-ms-transform-origin:50% 0;transform-origin:50% 0;-webkit-transform:scale(0.75) translateY(-21px);-ms-transform:scale(0.75) translateY(-21px);transform:scale(0.75) translateY(-21px);-webkit-transition:all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;transition:all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;z-index:9;}
.nice-select .option{cursor:pointer;font-weight:400;line-height:40px;list-style:none;min-height:40px;outline:none;padding-left:18px;padding-right:29px;text-align:left;-webkit-transition:all 0.2s;transition:all 0.2s;}
.nice-select .option:hover{background-color:#f6f6f6;}
.nice-select .option.selected{font-weight:bold;}
/*! CSS Used from: https://demo.themeies.com/supermart-html/assets/css/style.css */
*{margin:0;padding:0;-webkit-box-sizing:border-box;box-sizing:border-box;}
h2{font-weight:400;}
ul{margin:0;padding:0;list-style:inherit;}
.mb-30{margin-bottom:30px!important;}
::-webkit-scrollbar{width:4px;height:4px;}

::-webkit-scrollbar-thumb{background-color:#a7a7a7;border-radius:10px;}
::-moz-selection{background:#a7a7a7;color:#fff;}
::selection{background:#a7a7a7;color:#fff;}
.row > *{max-width:100%;padding-right:calc(var(--bs-gutter-x) * 1);padding-left:calc(var(--bs-gutter-x) * 1);margin-top:var(--bs-gutter-y);}
h2{font-family:"Prompt", sans-serif;font-size:36px;font-weight:700;line-height:44px;letter-spacing:-0.02em;color:#252522;}
@media screen and (max-width: 767px){
h2{font-size:30px;}
}
@media screen and (max-width: 575px){
h2{line-height:34px;}
}
.section-title{margin-bottom:40px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;}
.section-title h2{margin-bottom:0;}
.select__style .nice-select{border:none;font-size:inherit;line-height:inherit;height:inherit;background:transparent;width:130px;padding:10px 30px 10px 15px;border-right:1px solid #eaecf0;}
.select__style .nice-select::after{height:8px;width:8px;}
.select__style .nice-select .option{min-height:inherit;padding:7px 15px;}
.select__style .nice-select .option:hover{background:rgba(235, 94, 40, 0.1);color:#252522;}
.select__style .nice-select .current,.select__style .nice-select .list li{font-family:"Prompt", sans-serif;font-size:16px;font-weight:500;line-height:24px;color:#667085;}
.select__style .nice-select .list{width:100%;max-height:250px;overflow:auto;-webkit-box-shadow:-24px 24px 72px 17px rgba(145, 158, 171, 0.24);box-shadow:-24px 24px 72px 17px rgba(145, 158, 171, 0.24);}
@media screen and (max-width: 575px){
.archive-category .section-title{-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;}
}
.shortBy-select{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;height:40px;}
.shortBy-select label{font-family:"Prompt", sans-serif;font-size:16px;font-weight:500;line-height:24px;color:#98a2b3;}
.shortBy-select .nice-select{background:transparent;border:none;padding:0;color:#344054;width:120px;margin-left:5px;height:40px;line-height:40px;}
@media print{
*{color:#000000!important;}
}
.list{list-style:none;}
.pz-pdb-item {
    width: 100%;
    display: inline-block;
    border: 0px solid rgb(226 226 226);
    border-radius: 8px;
    margin: 1% 0;
    background-color: #fff;
}.pz-pdb_media--img{
    width: 100%;
    max-width: 200px;
    min-width: 200px;
	height: 200px;
    border-radius: 4px;
}
@media (min-width: 768px){

.pz-pdb_media{
    min-height: 200px;
}}
</style>
